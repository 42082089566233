<template>
  <div class="default-tabela numeros-tabela">
    <div class="tabela-titulo">
      <h1>{{ camposInput.titulo }}</h1>
      <div style="position: relative;">
        <DownloadButton
          area="relatorio_de_lojistas"
          :getData="downloadExcelAll"
          :title="'Relatório de Números da Sorte'"
        />
      </div>
    </div>

    <div class="tabela-subtitulo default-formulario">
      <div class="formulario-item">
        <label class="formulario-item-label">Nome do participante:</label>
        <input type="text" v-model="form.nome" placeholder="Nome" />
      </div>
      <div class="formulario-item">
        <label class="formulario-item-label">CPF:</label>
        <input
          type="text"
          v-model="form.cpf"
          v-mask="'999.999.999-99'"
          placeholder="000.000.000-00"
        />
      </div>
      <div class="formulario-item">
        <label class="formulario-item-label">Número da Sorte:</label>
        <input type="text" v-model="form.numeroSorte" placeholder="0.00000" />
      </div>
      <div class="formulario-item">
        <label class="formulario-item-label">Número do cupom:</label>
        <input type="text" v-model="form.numeroCupom" placeholder="0000000000" />
      </div>
      <div class="formulario-item">
        <label class="formulario-item-label">Filtre por status</label>
        <multiselect
          v-model="form.status"
          :options="statusCupom"
          label="descricao"
          track-by="id"
          :searchable="false"
          :allow-empty="false"
          placeholder="Selecione"
          select-label="Filtrar"
          deselect-label="Selecionado"
          selected-label="Selecionado"
        />
      </div>
    </div>
    <!-- .tabela-filtro -->
    <div class="tabela-subtitulo default-formulario">

      <div class="btns-formulario" style="margin-left: auto;">
        <button class="btn btn-enviar" @click="filtrar">Filtrar</button>
        <button class="btn" @click="limparFiltros">Limpar</button>
      </div>

      <!-- .tabela-filtro -->
    </div>

    <transition name="fade-left" mode="out-in">
      <div v-if="semDados" class="tabela-sem-dados" key="Vazio">
        <p>Sem dados no momento</p>
      </div>
      <div class="tabela-informacoes" key="Lista">
        <div class="tabela-linha tabela-linha-titulo">
          <div class="tabela-linha-nome"><p>Nome usuário</p></div>
          <div class="tabela-linha-cpf"><p>CPF</p></div>
          <div class="tabela-linha-desktop"><p>Número da Sorte</p></div>
          <div class="tabela-linha-cupom"><p>Número Cupom</p></div>
          <div class="tabela-linha-nome"><p>Status</p></div>
          <!-- <div class="tabela-linha-btns"></div> -->
        </div>
        <div
          class="tabela-linha tabela-linha-informacoes"
          v-for="itemTabela in dadosTabela"
          :key="itemTabela.id"
        >
          <div class="tabela-linha-nome">
            <p>
              {{ itemTabela.nome }}
            </p>
          </div>
          <div class="tabela-linha-cpf">
            <p>
              {{ itemTabela.cpf | formataCPF }}
            </p>
          </div>
          <div class="tabela-linha-desktop">
            <p>
              {{ itemTabela.numeroSorte }}
            </p>
          </div>
          
          <div class="tabela-linha-cupom">
            <p>{{ itemTabela.numeroCupom }}</p>
          </div>
          <!-- <div class="tabela-linha-nome">
            <p>{{ itemTabela.status }}</p>
          </div> -->

          <div class="tabela-linha-nome">
            <p
              v-if="itemTabela.status"
              :class="`cupom-status ${itemTabela.status}`"
            >
              {{ itemTabela.status }}
            </p>
            <p v-if="!itemTabela.status">Não informado</p>
          </div>

          <!-- <div class="tabela-linha-btns">
            <div
              class="tabela-linha-btns-editar"
              @click="verItem(itemTabela.id)"
            >
              <img
                src="~@/assets/images/icones/see.svg"
                alt="Detalhes"
                title="Detalhes"
              />
            </div>
          </div> -->
        </div>
      </div>
    </transition>
    <div class="tabela-paginacao paginacao" v-if="!semDados">
      <div>
        <p>Total</p>
      </div>
      <div>
        {{ paginacao.totalPaginas }}
      </div>
      <div
        class="paginacao-btn"
        v-if="paginacao.numeroPagina >= 2"
        @click="nudaPagina(1)"
      >
        <p>1</p>
      </div>
      <div>
        <div class="button" v-if="pageNumber > 1" @click="prevPage()">
          <img
            src="~@/assets/images/icones/next.svg"
            alt="<"
            title="Anterior"
            style=" transform: rotate(180deg);"
          />
        </div>
      </div>
      <div
        v-if="paginacao.numeroPagina > 1"
        @click="nudaPagina(paginacao.numeroPagina - 1)"
      >
        <p>{{ paginacao.numeroPagina - 1 }}</p>
      </div>
      <div class="pagina-atual" v-if="paginacao.numeroPagina >= 1">
        {{ paginacao.numeroPagina }}
      </div>
      <div
        v-if="
          paginacao.totalPaginas >= 3 &&
            paginacao.numeroPagina < paginacao.totalPaginas
        "
        @click="nudaPagina(paginacao.numeroPagina + 1)"
      >
        <p>{{ paginacao.numeroPagina + 1 }}</p>
      </div>
      <div>
        <div
          class="button"
          v-if="dadosTabela.length === 10"
          @click="nextPage()"
        >
          <img src="~@/assets/images/icones/next.svg" alt=">" title="Próximo" />
        </div>
      </div>
      <div
        class="paginacao-btn"
        v-if="paginacao.numeroPagina < paginacao.totalPaginas"
        @click="nudaPagina(paginacao.totalPaginas)"
      >
        <p>{{ paginacao.totalPaginas }}</p>
      </div>
    </div>
    <!-- .tabela-paginacao -->
  </div>
</template>

<script>
import { numeroListarTodos, gerarRelatorio, GetExcelAdminById, cupomListarStatus } from "@/api/numero";
import { _imagemMobile, _imagemDesktop } from "@/api/_caminhosApi";
import { numberToReal } from "@/utils/money";
import { filterDataHora, filterData } from "@/utils/date";
import DownloadButton from "@/components/download-button/DownloadButton.vue";
import { formataCPF } from "@/utils/masks";7
import AwesomeMask from "awesome-mask";
import Multiselect from "vue-multiselect";

// import moment from "moment";
let filtrosBase = {
  nome: null,
  numeroSorte: null,
  cpf: null,
  numeroCupomcupom: null,
  status: { descricao: "Todos", id: "" }
};

export default {
  directives: {
    mask: AwesomeMask
  },
  data() {
    return {
      planilhaParaDownload: [],
      tituloDownload: "",
      pageNumber: 1,
      filtroBotao: false,
      semDados: true,
      form: filtrosBase,
      filtros: {}
    };
  },
  components: {
    DownloadButton,
    Multiselect,
  },
  computed: {
    camposInput() {
      return this.$store.state.numero.componenteAtivado;
    },
    dadosTabela() {
      return this.$store.state.numero.tabela;
    },
    paginacao() {
      return this.$store.state.numero.paginacao;
    },
    statusCupom() {
      console.log(this.$store.state.numero.statuses)
      return this.$store.state.numero.statuses;
    },
  },
  methods: {
    _imagemMobile,
    _imagemDesktop,
    numberToReal,
    gerarRelatorio,
    formataCPF(cpf) {
      cpf = cpf.replace(/[^\d]/g, "");
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },
    buscaStatus() {
      return cupomListarStatus();
    },
    montarDownload(data) {
      this.tituloDownload = ["Relatório de Número da Sorte"];
      if (data && Object.keys(data).length) {
        return (this.planilhaParaDownload = data.map(v => ({
          CPF: v.cpf,
          Nome: v.nome,
          "Data geração": v.dataCadastro.slice(0,-3),
          // "Número da Sorte": v.numeroSorte,
          "Numero de Serie": v.numeroSerie,
          "Numero de Ordem": v.numeroOrdem,
          cep: v.cep,
          Endereço: v.endereco,
          Bairro: v.bairro,
          Cidade: v.cidade,
          Numero: v.numero,
          Complemento: v.complemento,
          UF: v.uf,
        })));
      }
    },
    prevPage() {
      this.pageNumber = this.pageNumber - 1;
    },
    nextPage() {
      this.pageNumber = this.pageNumber + 1;
    },
    nudaPagina(id) {
      this.pageNumber = id;
    },
    novoEnvio() {
      this.$store.commit("numero/SET_TIPO_FORMULARIO", "Novo");
      this.$store.commit("numero/SET_NUMERO_TELA", "Formulario");
    },
    verificaComponente() {
      return numeroListarTodos(this.pageNumber, 10, this.form, this.filtroBotao);
    },
    editarItem(id) {
      this.$store.commit("numero/SET_TIPO_FORMULARIO", "Editar");
      this.$store.commit("numero/SET_NUMERO_TELA", "Formulario");
      this.$store.commit("numero/SET_ID_FORMULARIO_EDITAR", id);
    },
    verItem(id) {
      this.$store.commit("numero/SET_TIPO_FORMULARIO", "Detalhes");
      this.$store.commit("numero/SET_NUMERO_TELA", "Detalhes");
      this.$store.commit("numero/SET_ID_FORMULARIO_EDITAR", id);
    },
    filtrar() {
      this.filtros = this.form;
      this.pageNumber = 1
      this.filtroBotao = true
      this.verificaComponente();
      this.filtroBotao = false
    },
    limparFiltros() {
      this.form = {
        nome: null,
        numeroSorte: null,
        cpf: null,
        numeroCupom: null,
        status: { descricao: "Todos", id: "" }
      };
      this.filtros = {};
      this.pageNumber = 1
      this.filtroBotao = false
      this.verificaComponente();
    },
    getData() {
      return this.gerarRelatorio().then(resp => {
        return this.montarDownload(resp.data);
      });
    },
    downloadExcelAll() {
      GetExcelAdminById().then(
        (res) => {
          const fileURL = window.URL.createObjectURL(new Blob([res.data],{
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }));
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "RelatorioNumerosSorte.xlsx");
          document.body.appendChild(fileLink);
          fileLink.click();
          this.$store.commit("interacoes/SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_ALERTA", {
            show: true,
            tipo: "erro",
            mensagem: error.response.data,
          });
        }
      );
    },
  },
  filters: {
    filterDataHora,
    filterData,
    formataCPF
  },
  mounted() {
    this.pageNumber = 1;
    // this.gerarRelatorio();
    this.verificaComponente();
    this.montarDownload();
    this.buscaStatus();
  },
  watch: {
    camposInput() {
      this.verificaComponente();
    },
    dadosTabela() {
      console.log(this.dadosTabela);
      this.semDados = this.dadosTabela.length <= 0;
    },
    pageNumber() {
      this.verificaComponente();
    }
  }
};
</script>

<style lang="scss" scoped>
.tabela-linha-cupom {
  min-width: 125px;
  max-width: 125px;
  overflow: hidden;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
