<template>
  <div>
    <transition name="fade-left" mode="out-in">
      <div class="numeros" v-if="formOuLista === 'Lista'" key="Lista">
        <Tabela />
      </div>
      <div v-if="formOuLista === 'Detalhes'" class="numeros-formulario" key="Detalhes">
        <TelaVer />
      </div>
    </transition>
  </div>
</template>

<script>
import Tabela from "@/components/numero/Tabela.vue"; 
import TelaVer from "@/components/numero/TelaVer.vue"; 
export default {
  components: {
    Tabela,
    TelaVer
  },
  data() {
    return {
      tela: "",
      telas: [
        {
          id: 1,
          ativado: true,
          titulo: "Números da Sorte",
          componente: "NumeroSorte"
        }
      ]
    };
  },
  computed: {
    formOuLista() {
      return this.$store.state.numero.telaAtivada;
    },
    componentesMenu() {
      return this.telas.filter(item => item.ativado);
    }
  },
  created() {
    this.$store.commit("numero/SET_LINKATIVADO", this.componentesMenu[0].componente);
    this.$store.commit("numero/SET_COMPONENTE", this.componentesMenu[0]);
    this.$store.commit("numero/SET_NUMERO_TELA", "Lista");
  },
  watch: {
    formOuLista() {
      if (this.$store.state.interacoes.modal.modalAtivado) {
        this.$store.commit("interacoes/SET_ALERTA", {
          ativado: false,
          mensagem: "",
          tipo: ""
        });
      }
    },
    componentesMenu() {
      if (this.$store.state.interacoes.modal.modalAtivado) {
        this.$store.commit("interacoes/SET_ALERTA", {
          ativado: false,
          mensagem: "",
          tipo: ""
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.numeros {
    display: flex;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;

    .numeros-tabela {
        width: 100% !important;
    }
}
.numeros-formulario {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 25px;

    @media (max-width: 750px) {
        padding: 25px 10px;
    }
}
</style>
