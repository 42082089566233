import client from "@/api";
import store from "@/store";
import axios from "axios";
import { getFirstError } from "@/utils/errorResponse";
import { limpaCPF, limpaCNPJ } from "@/utils/masks";
import {
  _rotaAtualizarCupom,
  _rotaListarCupomStatus,
  // _rotaListarCupomPorStatus,
  _rotaListarTodosCupons,
  _rotaListarProdutosPorCupom,
  _rotaGerarRelatorioCupons,
  _rotaGerarRelatorioCuponsTotal,
  _rotaListarCupomObterDetalhes,
  _rotaListarMotivoReprovaCupom
} from "@/api/_caminhosApi";
import moment from "moment";


export function cupomListarStatus() {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("cupom/SET_STATUS_ITENS", "");
  client
    .get(`${_rotaListarCupomStatus()}`)
    .then((resp) => {
      let filtros = [{ descricao: "Todos", id: null }, ...resp.data.status];
      store.commit("cupom/SET_STATUS_ITENS", filtros);
    })
    .catch((erro) => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function downloadWithAxios(url, title) {
  return new Promise((resolve) => {
    client
      .get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((resp) => {
        console.log(resp);
        fetch(resp.data.url)
          .then((resp) => resp.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = title;
            document.body.appendChild(a);
            if (a.download !== "null") {
              a.click();
            }
            window.URL.revokeObjectURL(url);
          });
        resolve(resp);
      })
      .catch((erro) => {
        alert("Arquivo não pôde ser baixado.");
        console.log(erro);
      });
  });
}

export async function obterDetalhes(id) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("cupom/SET_TABELA_DETALHE", "");
  return new Promise((resolve, reject) => {
  client
    .get(`${_rotaListarCupomObterDetalhes()}/${parseInt(id)}`)
    .then((resp) => {
      store.commit("cupom/SET_TABELA_DETALHE", resp);
      resolve();
    })
    .catch((erro) => {
      console.log(erro);
      reject();
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
  });
}

export function cupomListarTodos(page, qtd, filtro, btn) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("cupom/SET_TABELA", "");

  let rota = `?pagina=${page}&quantidadeItemPorPagina=${qtd}&filtroBotao=${btn}`;
  
  let formData = new FormData();
  formData.append("Nome", filtro && filtro.nome ? filtro.nome : "")
  formData.append("CPF", filtro && filtro.cpf ? limpaCPF(filtro.cpf) : "")
  formData.append("CNPJ", filtro && filtro.cnpj ? limpaCNPJ(filtro.cnpj) : "")
  formData.append("DataCadastroParticipacaoInicial", filtro && filtro.dataInicial ? moment(filtro.dataInicial).format("DD/MM/YYYY") : "")
  formData.append("DataCadastroParticipacaoFinal", filtro && filtro.dataFinal ? moment(filtro.dataFinal).format("DD/MM/YYYY") : "")
  formData.append("PossuiPremio", filtro && filtro.premiacaoInstantanea != null ? filtro.premiacaoInstantanea : "")
  // formData.append("StatusAtual", filtro && filtro.status.id ? filtro.status.id : "")
  formData.append("StatusAtual", 1)

  client
    .post(`${_rotaListarTodosCupons()}${rota}`, formData)
    .then((resp) => {
      store.commit("cupom/SET_TABELA", resp.data.lista);
      store.commit("cupom/SET_DATA_PAGINACAO", resp.data.paginacao);
    })
    .catch((erro) => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function GetExcelAdminById() {
	return new Promise( (resolve, reject) => {
		axios({
			url: `${process.env.VUE_APP_API_BASE}/Relatorio/ListarParticipacaoEmAnalise`,
			method: "GET",
			responseType: "blob",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
		})
		.then( res => { resolve(res) })
		.catch( err => { reject(err) })
	})	
}

export function aprovarCupom(id) {
  store.commit("interacoes/SET_LOADING", true);
  let formData = new FormData();
  formData.append("participacaoId", id)

  client
    .post(`/Participacao/Aprovar`, formData)
    .then((resp) => {
      store.commit("interacoes/SET_MODAL_ALERTA", {
        ativado: true,
        mensagem: resp.data.mensagem,
        tipo: "sucesso",
      });
      store.commit("cupom/SET_CUPOM_TELA", "Lista");
    })
    .catch((erro) => {
      console.log(erro);
      store.commit("interacoes/SET_MODAL_ALERTA", {
        ativado: true,
        mensagem: erro.response.data
          ? getFirstError(erro.response.data)
          : "Erro inesperado. Informe a área técnica.",
        tipo: "erro",
      });
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function reprovarCupom(motivoId) {
  store.commit("interacoes/SET_LOADING", true);

  let formData = new FormData();
  formData.append("participacaoId", store.state.cupom.formularioEditarId)
  formData.append("motivoReprovaId", motivoId)

  client
    .post(`/Participacao/Reprovar`, formData)
    .then((resp) => {
      store.commit("interacoes/SET_MODAL_ALERTA", {
        ativado: true,
        mensagem: resp.data.mensagem,
        tipo: "sucesso",
      });
      store.commit("cupom/SET_CUPOM_TELA", "Lista");
    })
    .catch((erro) => {
      console.log(erro);
      store.commit("interacoes/SET_MODAL_ALERTA", {
        ativado: true,
        mensagem: erro.response.data
          ? getFirstError(erro.response.data)
          : "Erro inesperado. Informe a área técnica.",
        tipo: "erro",
      });
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
//
export async function cupomAtualizar(bodyData, motivoReprovacao) {
  // Verifica status do cupom antes de prosseguir
  console.log(store.state.cupom.tabelaDetalhe)
  await obterDetalhes(bodyData.id)

  var proceed = false;

 if(bodyData.cupomStatus.id === 1 && store.state.cupom.tabelaDetalhe.data.idStatus === 3){
  proceed = true;
 } else if(bodyData.cupomStatus.id === 3 && store.state.cupom.tabelaDetalhe.data.idStatus === 2) {
  proceed = true;
 } else if(bodyData.cupomStatus.id === 1 && store.state.cupom.tabelaDetalhe.data.idStatus === 2) {
  proceed = true;
 }

 if(bodyData.cupomStatus.id === 3 && store.state.cupom.tabelaDetalhe.data.idStatus === 3) {
  store.commit("interacoes/SET_MODAL_ALERTA", {
    ativado: true,
    mensagem: `Este cupom já foi reprovado`,
    tipo: "erro",
  });

  this.$store.commit("interacoes/SET_ALERTA_BLOQUEIO", {
    ativado: false
  });
  return;
 }


  if(store.state.cupom.tabelaDetalhe.data.idStatus === 1) {
    store.commit("interacoes/SET_MODAL_ALERTA", {
      ativado: true,
      mensagem: `Cupom já avaliado`,
      tipo: "erro",
    });

    this.$store.commit("interacoes/SET_ALERTA_BLOQUEIO", {
      ativado: false
    });
    return;
  }

  if(proceed){
    if (!motivoReprovacao && bodyData.cupomStatus.id != 1) {
      store.commit("interacoes/SET_MODAL_ALERTA", {
        ativado: true,
        mensagem: "Descreva o motivo da reprovação.",
        tipo: "erro",
      });
      store.commit("interacoes/SET_LOADING", false);
      this.$store.commit("interacoes/SET_ALERTA_BLOQUEIO", {
        ativado: false,
        mensagem: "",
        tipo: "",
      });
      return;
    }
    bodyData.motivoReprovacao = motivoReprovacao;
    bodyData.idUsuarioPainel = window.localStorage.getItem("idUser");
    store.commit("interacoes/SET_LOADING", true);
    client
      .put(`${_rotaAtualizarCupom()}`, bodyData)
      .then((resp) => {
        store.commit("interacoes/SET_MODAL_ALERTA", {
          ativado: true,
          mensagem: resp.data.mensagem,
          tipo: "sucesso",
        });
        store.commit("cupom/SET_CUPOM_TELA", "Lista");
      })
      .catch((erro) => {
        console.log(erro);
        store.commit("interacoes/SET_MODAL_ALERTA", {
          ativado: true,
          mensagem: erro.response.data
            ? getFirstError(erro.response.data)
            : "Erro inesperado. Informe a área técnica.",
          tipo: "erro",
        });
      })
      .finally(() => {
        store.commit("interacoes/SET_LOADING", false);
        this.$store.commit("interacoes/SET_ALERTA_BLOQUEIO", {
          ativado: false,
          mensagem: "",
          tipo: "",
        });
      });
    } 
}
//
export function cupomListarProdutos(cupomId) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("cupom/SET_PRODUTOS_MODERAR", "");
  client
    .get(`${_rotaListarProdutosPorCupom()}?cupomId=${cupomId}`)
    .then((resp) => {
      store.commit("cupom/SET_PRODUTOS_MODERAR", resp.data);
    })
    .catch((erro) => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function gerarRelatorio() {
  return new Promise((resolve) => {
    store.commit("interacoes/SET_LOADING_RELATORIO", true);
    client
      .get(_rotaGerarRelatorioCupons())
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        console.log(erro);
      })
      .finally(() => {
        store.commit("interacoes/SET_LOADING_RELATORIO", false);
      });
  });
}
export function gerarRelatorioTotal() {
  return new Promise((resolve) => {
    store.commit("interacoes/SET_LOADING_RELATORIO", true);
    client
      .get(_rotaGerarRelatorioCuponsTotal())
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        console.log(erro);
      })
      .finally(() => {
        store.commit("interacoes/SET_LOADING_RELATORIO", false);
      });
  });
}

export function listarMotivoReprovaCupom() {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("cupom/SET_PRODUTOS_MODERAR", "");
  client
    .get(_rotaListarMotivoReprovaCupom())
    .then((resp) => {
      console.log(resp)
      store.commit("cupom/SET_MOTIVO_REPROVA", resp.data.motivoReprova);
    })
    .catch((erro) => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
