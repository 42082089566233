import client from "@/api";
import store from "@/store";
import axios from "axios";
import { _rotaListarNumeros, _rotaGerarRelatorio, _rotaListarCupomStatus } from "@/api/_caminhosApi";
import { limpaCPF } from "@/utils/masks";

//
export function numeroListarTodos(page, qtd, filtro, btn) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("numero/SET_TABELA", "");
  
  let formData = new FormData();
  formData.append("Nome", filtro && filtro.nome ? filtro.nome : "")
  formData.append("Cpf", filtro && filtro.cpf ? limpaCPF(filtro.cpf) : "")
  formData.append("NumeroSorte", filtro && filtro.numeroSorte ? filtro.numeroSorte : "")
  formData.append("NumeroCupom", filtro && filtro.numeroCupom ? filtro.numeroCupom : "")
  formData.append("Status", filtro && filtro.status.id)

  client
    .post(`${_rotaListarNumeros()}?pagina=${page}&quantidadeItemPorPagina=${qtd}&filtroBotao=${btn}`, formData)
    .then(resp => {
      store.commit("numero/SET_TABELA", resp.data.lista);
      store.commit("numero/SET_DATA_PAGINACAO", resp.data.paginacao);
    })
    .catch(erro => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function cupomListarStatus() {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("numero/SET_STATUS_ITENS", "");
  client
    .get(`${_rotaListarCupomStatus()}`)
    .then((resp) => {
      let filtros = [{ descricao: "Todos", id: '' }, ...resp.data.status];
      store.commit("numero/SET_STATUS_ITENS", filtros);
    })
    .catch((erro) => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function GetExcelAdminById() {
	return new Promise( (resolve, reject) => {
		axios({
			url: `${process.env.VUE_APP_API_BASE}/Relatorio/ListarNumerosSorte`,
			method: "GET",
			responseType: "blob",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
		})
		.then( res => { resolve(res) })
		.catch( err => { reject(err) })
	})	
}

export function gerarRelatorio() {
  return new Promise((resolve) => {
  store.commit("interacoes/SET_LOADING_RELATORIO", true);
  client
    .get(_rotaGerarRelatorio())
    .then((resp) => {
      resolve(resp);
    })
    .catch((erro) => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING_RELATORIO", false);
    });
  });
}
