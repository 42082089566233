<template>
  <div class="default-moderar moderar-numero">
    <div class="moderar-numero-container">
      <h1>{{ camposInput.titulo }} <small>Detalhes</small></h1>
      <div class="moderar-body">
        <div class="moderar-row">
          <div class="moderar-panel">
            <h3>Informações do usuário:</h3>
            <div
              class="moderar-row"
              v-if="
                informacoesTabelaAtivada && informacoesTabelaAtivada.nomeUsuario
              "
            >
              <div class="moderar-item w-33">
                <label>Nome:</label><br />
                <p>{{ informacoesTabelaAtivada.nomeUsuario }}</p>
              </div>
              <div class="moderar-item w-33">
                <label>E-mail:</label><br />
                <p>{{ informacoesTabelaAtivada.emailUsuario }}</p>
              </div>
              <div class="moderar-item w-33">
                <label>CPF:</label><br />
                <p>
                  {{ informacoesTabelaAtivada.cpfUsuario | formataCPF }}
                </p>
              </div>
            </div>
            <!-- <div class="moderar-row">
              <div class="moderar-item">
                <label>E-mail:</label><br />
                <p>{{ informacoesTabelaAtivada.emailUsuario }}</p>
              </div>
            </div> -->
            <div
              class="moderar-row"
              v-if="
                informacoesTabelaAtivada && informacoesTabelaAtivada.nomeUsuario
              "
            >
              <div class="moderar-item w-33">
                <label>Ativo:</label><br />
                <p>
                  {{ informacoesTabelaAtivada.usuarioAtivo ? "Sim" : "Não" }}
                </p>
              </div>
              <div class="moderar-item w-33">
                <label>Data Atualização:</label><br />
                <p>
                  {{
                    informacoesTabelaAtivada.dataAtualizacaoUsuario
                      | filterDataHora
                  }}
                </p>
              </div>
              <div class="moderar-item w-33">
                <label>Data Cadastro:</label><br />
                <p>
                  {{
                    informacoesTabelaAtivada.dataCadastroUsuario
                      | filterDataHora
                  }}
                </p>
              </div>
            </div>

            <h3 class="mt-3">Informações do número da sorte:</h3>
            <div class="moderar-row">
              <div class="moderar-item w-33">
                <label>Número da sorte:</label><br />
                <p>
                  {{ informacoesTabelaAtivada.serieNumeroOrdem }}.{{
                    informacoesTabelaAtivada.numeroOrdem
                  }}
                </p>
              </div>
              <!-- <div class="moderar-item w-33">
                <label>Série:</label><br />
                <p>{{ informacoesTabelaAtivada.serieNumeroOrdem }}</p>
              </div> -->
              <div class="moderar-item w-33">
                <label>Data de criação:</label><br />
                <p>
                  {{
                    informacoesTabelaAtivada.dataCriacaoNumeroOrdem
                      | filterDataHora
                  }}
                </p>
              </div>
            </div>

            <!-- <h3 class="mt-3">Informações do Cupom:</h3>
            <div class="moderar-row">
              <div class="moderar-item w-33">
                <label>Número do cupom:</label><br />
                <p>{{ informacoesTabelaAtivada.numeroCupom }}</p>
              </div>
              <div class="moderar-item w-33">
                <label>Status do cupom:</label><br />
                <p>
                  {{
                    informacoesTabelaAtivada.idStatusCupom
                      ? informacoesTabelaAtivada.descricaoStatusCupom
                      : "--"
                  }}
                </p>
              </div>
              <div class="moderar-item w-33">
                <label>Data da compra:</label><br />
                <p>
                  {{
                    informacoesTabelaAtivada.dataCompraCupom | filterDataHora
                  }}
                </p>
              </div>
            </div>
            <div class="moderar-row">
              <div class="moderar-item w-66">
                <label>CNPJ do estabelecimento:</label><br />
                <p>{{ informacoesTabelaAtivada.cnpjCupom }}</p>
              </div>
              <div class="moderar-item w-33">
                <label>UF:</label><br />
                <p>{{ informacoesTabelaAtivada.ufCupom }}</p>
              </div>
            </div>
            <div class="moderar-row">
              <div class="moderar-item w-66">
                <label>Data de cadastro do cupom:</label><br />
                <p>
                  {{
                    informacoesTabelaAtivada.dataCadastroCupom | filterDataHora
                  }}
                </p>
              </div>
            </div> -->
          </div>

          <!-- <div class="moderar-panel">
            <h3>Foto do Cupom:</h3>
            <div class="moderar-row">
              <div class="moderar-item">
                <div class="moderar-row">
                  <div class="moderar-item">
                    <label>Nome da imagem:</label><br />
                    {{ informacoesTabelaAtivada.imagemCupom }}
                  </div>
                  <div class="moderar-item">
                    <label>Baixar imagem:</label>
                    <a class="btn btn-download-img" @click="baixarImagem()"
                      >baixar</a
                    >
                  </div>
                </div>
                <br />
                <ImagemCupom
                  :imagem="imgBaixar"
                  v-if="informacoesTabelaAtivada.imagemCupom != null"
                />
                <p v-else>Sem foto</p>
              </div>
            </div>
          </div> -->
        
        </div>
      </div>
    </div>
    <div class="btns-formulario">
      <button class="btn btn-cancelar" @click="cancelarFormuario">
        Voltar
      </button>
    </div>
  </div>
</template>

<script>
import { _imagemCupom } from "@/api/_caminhosApi";
import { downloadWithAxios } from "@/api/cupom";
import { numberToReal } from "@/utils/money";
import { filterData, filterDataHora } from "@/utils/date";
import { formataCPF } from "@/utils/masks";
// import ImagemCupom from "@/components/imagemZoom/ImagemZoom.vue";

export default {
  components: {
    // ImagemCupom
  },
  computed: {
    camposInput() {
      return this.$store.state.numero.componenteAtivado;
    },
    formularioTipo() {
      return this.$store.state.numero.formularioTipo;
    },
    tabela() {
      return this.$store.state.numero.tabela;
    },
    idTabelaAtivada() {
      return this.$store.state.numero.formularioEditarId;
    },
    informacoesTabelaAtivada() {
      return this.tabela.filter(item => item.id == this.idTabelaAtivada)[0];
    },
    imgBaixar() {
      var img = this.tabela.filter(item => item.id == this.idTabelaAtivada)[0]
        .imagemCupom;
      return `${process.env.VUE_APP_URL_IMG_S3}/` + img;
    }
  },
  filters: {
    filterData,
    filterDataHora,
    formataCPF
  },
  methods: {
    _imagemCupom,
    numberToReal,
    cancelarFormuario() {
      this.$store.commit("numero/SET_NUMERO_TELA", "Lista");
    },
    baixarImagem() {
      const url = `${process.env.VUE_APP_API_BASE}/Cupom/GerarLink?nomeImagem=${this.informacoesTabelaAtivada.imagemCupom}`;
      downloadWithAxios(url, this.informacoesTabelaAtivada.imagemCupom);
    }
  }
};
</script>

<style lang="scss" scoped>
.w-33 {
  width: 33%;
  margin-left: 0 !important;
}
.w-66 {
  width: 66%;
}
.mt-3 {
  margin-top: 3em !important;
}
.moderar-numero {
  .btns-formulario {
    justify-content: space-evenly;
  }
  // .btn-enviar {
  //   &.btn-reprovar {
  //     background-color: red;
  //   }
  //   &.btn-aprovar {
  //     background-color: green;
  //   }
  // }
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
